<template>
  <div>
    <div class="app-header">
      <img :alt="$config.client.name" :src="logoPath"/>
    </div>
    <div class="center">
      <b-container class="msg">
        <p>
          {{ $t('signout.message') }}<br>
        </p>
        <b-button type="button" variant="outline-primary" data-test="signOut-signIn" @click.prevent="$router.push({ name: 'SignIn' })">
          {{ $t('signout.toSignIn') }}
        </b-button>
      </b-container>
    </div>
    <small class="footer">
      <p>{{ $t('footer') }} <a href="https://www.neogeo.fr/" target="_blank" rel="noopener">Neogeo-Technologies</a></p>
    </small>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'SignOut',

  data: () => {
    return { };
  },

  computed: {
    ...mapState('sign-out', [
      'logged',
      'error'
    ]),

    logoPath() {
      return require(process.env.VUE_APP_LOGO);
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
        vm.GET_SIGNOUT().then(() => {
          if (vm.logged) {
            vm.$router.push({ name: 'SignOutFailed' })
          }
        })
    });
  },

  methods: {
    ...mapActions('sign-out', [
      'GET_SIGNOUT'
    ])
  }
};
</script>

<style lang="less" scoped>
.center {
  position: initial;
}

.center .msg.container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.center .msg.container,
.center .msg.container button {
  font-size: large;
}

.footer {
  position: absolute;
  bottom: 0;
  font-size: small;
}

.footer a {
  text-decoration: none;
}
</style>
